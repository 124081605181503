<template>
  <div
    class="flex h-full flex-col items-center justify-center space-y-spacing-lg">
    <img src="/assets/AdvisorReportLogo.jpg">
    <div class="flex flex-col items-center justify-center space-y-spacing-md">
      <p class="secu-subtitle text-center">
        {{ $t('intellectual_property_rights') }}
      </p>
      <p class="text-center">
        {{ $trp($t('site_assessment_info_advisor')) }}
      </p>
      <p class="secu-body-bold text-center">
        {{ $trp($t('copyright')) }}
      </p>
    </div>
  </div>
</template>
