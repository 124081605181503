function sortByName<T extends { name?: string }>(a: T, b: T): number {
  if (!a.name && !b.name)
    return 0
  if (!a.name)
    return 1
  if (!b.name)
    return -1
  return a.name.localeCompare(b.name)
}
export default function removeIrrelevantRisksAndAreasAndSort(
  areas: Area[],
): Area[] {
  if (!areas)
    return []
  return areas
    .map((area) => {
      if (!area.risks)
        return area

      const relevantRisks = area.risks.filter(
        risk => risk.probability && risk.impact,
      )

      return {
        ...area,
        risks: relevantRisks.sort(sortByName),
      }
    })
    .filter(area => (area.risks?.length ?? 0) > 0)
    .sort(sortByName)
}
