export default function determineSeverity(
  column: number,
  row?: number,
  reverse: boolean = false,
): Severity {
  const severityMatrix: { [key: number]: { [key: number]: string } } = {
    1: { 1: 'medium', 2: 'high', 3: 'very_high', 4: 'very_high' },
    2: { 1: 'medium', 2: 'high', 3: 'high', 4: 'very_high' },
    3: { 1: 'low', 2: 'medium', 3: 'high', 4: 'high' },
    4: { 1: 'low', 2: 'low', 3: 'medium', 4: 'medium' },
  }

  const risk = () => {
    if (reverse && column && row)
      return severityMatrix[5 - column][row]
    else if (column && row)
      return severityMatrix[column][row]

    return column === 1
      ? 'low'
      : column === 2
        ? 'medium'
        : column === 3
          ? 'high'
          : 'very_high'
  }

  const getColor = () => {
    if (risk() === 'very_high')
      return 'bg-signal-red-300'
    else if (risk() === 'high')
      return 'bg-signal-orange-300'
    else if (risk() === 'medium')
      return 'bg-signal-yellow-300'
    else return 'bg-signal-green-300'
  }

  return { risk: risk(), color: getColor() }
}
