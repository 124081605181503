import type { HTMLOptions, jsPDFOptions } from 'jspdf'
import html2pdf from 'html2pdf.js'

// Your function and usage as defined before

export default async function (
  fileName: string,
  element: HTMLElement,
  status: Ref,
) {
  status.value = true

  const DOCUMENT_OPTIONS = {
    orientation: 'l',
    unit: 'mm',
    format: 'a4',
  } as jsPDFOptions
  const HTML_OPTIONS = {
    windowWidth: 1122.66,
  } as HTMLOptions

  if (!(element instanceof HTMLElement))
    throw new TypeError('usePDFExport: element is not a HTMLElement.')

  const orientation = element.offsetWidth > element.offsetHeight ? 'l' : 'p'

  const options = {
    filename: fileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      scale: 4, // too high scales like 12 will obviously slow down the browser and use around 10GB memory to render
      windowWidth: HTML_OPTIONS.windowWidth,
    },
    jsPDF: {
      orientation: DOCUMENT_OPTIONS?.orientation ?? orientation,
      unit: DOCUMENT_OPTIONS?.unit ?? 'px',
      format: DOCUMENT_OPTIONS?.format ?? 'A4',
    },
  }

  let worker
  const pages = Array.from(element.querySelectorAll('.pdf-page'))
  if (pages.length > 0) {
    worker = html2pdf().set(options).from(pages[0])
    worker = worker.toPdf()
    if (pages.length > 1) {
      for (let i = 1; i < pages.length; i++) {
        const page = pages[i]
        worker = worker
          .get('pdf')
          .then((pdf: { addPage: () => void }) => {
            pdf.addPage()
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf()
      }
    }
  }

  await worker.save()

  status.value = false
}
