<script setup lang="ts">
const props = defineProps<{
  risk: PdfRisk
  scenario: Scenario
  extra: boolean
}>()

const projectStore = useProjectStore()
const { scenarioImages } = storeToRefs(projectStore)

const imgIndex = props.extra ? 2 : 0

const convertImages = ref<string[]>([])

function readImage(file: File) {
  return URL.createObjectURL(file)
}

onMounted(async () => {
  const id = props.scenario.id as string
  if (scenarioImages.value.has(id)) {
    const images
      = scenarioImages.value.get(id)?.map(image => readImage(image)) ?? []
    convertImages.value = images
  }
})
</script>

<template>
  <div class="grid gap-y-spacing-md">
    <div class="flex justify-between">
      <div class="flex gap-spacing-lg">
        <div class="grid items-center">
          <p class="secu-body-micro">
            {{ $t('area') }}:
          </p>
          <p class="secu-body-bold">
            {{ $tx(risk.areaName) }}
          </p>
        </div>
        <div class="grid items-center">
          <p class="secu-body-micro">
            {{ $t('risk') }}:
          </p>
          <div class="flex items-center gap-spacing-sm">
            <p class="secu-body-bold">
              {{ $tx(risk.riskType.name) }}
            </p>
          </div>
        </div>
      </div>
      <ReportRiskRating :risk="risk" />
    </div>
    <div
      v-if="!extra"
      class="grid gap-spacing-md bg-secondary-gray-100 p-spacing-sm2">
      <div class="flex gap-spacing-2xl">
        <div>
          <p class="secu-body-small-bold">
            {{ $t('scenario_description') }}
          </p>
          <p class="secu-body-micro line-clamp-2">
            {{ $tx(scenario.scenarioType.name) }}
          </p>
        </div>
        <div>
          <p class="secu-body-small-bold">
            {{ $t('when') }}?
          </p>
          <div v-if="scenario.when" class="flex gap-spacing-sm">
            <p
              v-for="(occurance, key) in scenario.when"
              :key
              class="secu-body-micro line-clamp-2">
              {{
                $t(occurance) + (key + 1 >= scenario.when.length ? '' : ', ')
              }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p class="secu-body-small-bold">
          {{ $t('current_status') }}
        </p>
        <p class="secu-body-micro">
          {{ scenario.description }}
        </p>
      </div>
    </div>
    <div
      class="grid grow grid-cols-2 items-center justify-items-center gap-spacing-md">
      <div
        class="flex h-[360px] items-center justify-items-center"
        :class="{ 'h-[530px]': extra }">
        <img
          v-if="convertImages.at(imgIndex)"
          :src="convertImages.at(imgIndex)"
          class="h-auto max-h-full w-auto max-w-full rounded-md object-contain">
      </div>
      <div
        class="flex h-[360px] items-center justify-items-center"
        :class="{ 'h-[530px]': extra }">
        <img
          v-if="convertImages.at(imgIndex + 1)"
          :src="convertImages.at(imgIndex + 1)"
          class="h-auto max-h-full w-auto max-w-full rounded-md object-contain">
      </div>
    </div>
  </div>
</template>
