<script setup lang="ts">
const { riskLevels } = storeToRefs(useRiskDefinitionStore())
</script>

<template>
  <!-- <div class="grid grid-cols-2 gap-spacing-md2">
    <p class="secu-body-bold mb-spacing-md">
      {{ $t('probability') }}
    </p>
    <p class="secu-body-bold mb-spacing-md">
      {{ $t('impact') }}
    </p>
  </div> -->
  <div class="relative flex h-full flex-col justify-between gap-spacing-md">
    <p class="secu-body-bold">
      {{ $t('probability') }}
    </p>
    <div class="flex h-full w-full">
      <div
        v-for="(item, probability) in riskLevels"
        :key="probability"
        class="p-spacing-md">
        <div class="flex items-center space-x-spacing-xs">
          <div :class="`size-4 rounded-md ${item.highlightedColor}`" />
          <p class="secu-body-small-bold text-black">
            {{ item.name }}
          </p>
        </div>
        <ul
          v-for="(occurance, index) in item.probabilityDescriptions"
          :key="index"
          class="mt-spacing-sm flex list-inside list-disc items-center">
          <li class="secu-body-small ml-2 text-black">
            {{ $tx(occurance) }}
          </li>
        </ul>
      </div>
    </div>
    <p class="secu-body-bold">
      {{ $t('impact') }}
    </p>
    <div class="flex h-full w-full">
      <div
        v-for="(item, impact) in riskLevels"
        :key="impact"
        class="p-spacing-md">
        <div class="flex items-center space-x-spacing-xs">
          <div :class="`size-4 rounded-md ${item.highlightedColor}`" />
          <p class="secu-body-small-bold text-black">
            {{ $tx(item.name) }}
          </p>
        </div>
        <ul
          v-for="(occurance, index) in item.impactDescriptions"
          :key="index"
          class="mt-spacing-sm flex list-inside list-disc items-center">
          <li class="secu-body-small ml-2 text-black">
            {{ $tx(occurance) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
