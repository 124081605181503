<script setup lang="ts">
const { selectedProject } = storeToRefs(useProjectStore())
const { t } = useI18n()
const riskLevels = ref([
  {
    name: t('low'),
    color: 'bg-signal-green-300',
    totalRisks: 0,
  },
  {
    name: t('medium'),
    color: 'bg-signal-yellow-300',
    totalRisks: 0,
  },
  {
    name: t('high'),
    color: 'bg-signal-orange-300',
    totalRisks: 0,
  },
  {
    name: t('very_high'),
    color: 'bg-signal-red-300',
    totalRisks: 0,
  },
])

const filteredAreas = ref(
  removeIrrelevantRisksAndAreasAndSort(selectedProject.value?.areas ?? []),
)

function getColor(row: number, column: number) {
  const risk = determineSeverity(column, row).risk

  if (risk === 'very_high')
    return 'bg-signal-red-300'
  else if (risk === 'high')
    return 'bg-signal-orange-300'
  else if (risk === 'medium')
    return 'bg-signal-yellow-300'
  else return 'bg-signal-green-300'
}

function risksInSquare(probability: number, impact: number) {
  return filteredAreas.value
    .flatMap((a: Area) => a.risks as Risk[])
    .filter(
      (risk: Risk) =>
        risk.probability === 5 - probability && risk.impact === impact,
    )
}

function totalRisksPerLevel() {
  filteredAreas.value.forEach((area: Area) => {
    area?.risks?.forEach((risk: Risk) => {
      if (
        determineSeverity(5 - (risk.probability ?? 0), risk.impact ?? 0)
          .risk === 'very_high'
      ) {
        riskLevels.value[3].totalRisks++
      }
      else if (
        determineSeverity(5 - (risk.probability ?? 0), risk.impact ?? 0)
          .risk === 'high'
      ) {
        riskLevels.value[2].totalRisks++
      }
      else if (
        determineSeverity(5 - (risk.probability ?? 0), risk.impact ?? 0)
          .risk === 'medium'
      ) {
        riskLevels.value[1].totalRisks++
      }
      else {
        riskLevels.value[0].totalRisks++
      }
    })
  })
}

totalRisksPerLevel()
</script>

<template>
  <div class="grid h-full grid-cols-[62%_1fr] gap-spacing-md px-spacing-lg">
    <div class="relative">
      <div
        class="relative grid aspect-square grid-cols-4 grid-rows-4 overflow-hidden">
        <div
          v-for="(impact, columnIndex) in 4"
          :key="columnIndex"
          class="mr-0.5">
          <div
            v-for="(probability, rowIndex) in 4"
            :key="rowIndex"
            :class="`mt-0.5 flex h-full w-full flex-col items-center justify-center p-spacing-sm ${getColor(impact, probability)}`">
            <p
              v-if="risksInSquare(probability, impact)?.length"
              class="inline-flex rounded-2xl bg-primary-navyblue-900 px-spacing-lg py-spacing-xs text-white">
              {{ risksInSquare(probability, impact)?.length }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p
          class="secu-body-micro-bold absolute left-2 top-0 mt-spacing-sm h-fit w-fit rounded-[0.25rem] bg-primary-navyblue-900 p-spacing-sm py-spacing-xs text-white opacity-30">
          {{ $t('high') }}
        </p>
        <p
          class="secu-body-micro-bold absolute bottom-4 left-2 mb-spacing-sm h-fit w-fit rounded-[0.25rem] bg-primary-navyblue-900 p-spacing-sm py-spacing-xs text-white opacity-30">
          {{ $t('low') }}
        </p>
        <p
          class="secu-body-micro-bold absolute bottom-4 right-2 mb-spacing-sm h-fit w-fit rounded-[0.25rem] bg-primary-navyblue-900 p-spacing-sm py-spacing-xs text-white opacity-30">
          {{ $t('high') }}
        </p>
        <p
          class="secu-body-micro-bold absolute left-1 top-[48%] mb-spacing-sm rounded-[0.25rem] bg-[#031F3099] p-spacing-xs text-white">
          {{ $t('probability') }}
        </p>
        <p
          class="secu-body-micro-bold absolute bottom-4 left-[45%] mb-spacing-sm h-fit w-fit rounded-[0.25rem] bg-[#031F3099] px-spacing-sm py-spacing-xs text-white">
          {{ $t('impact') }}
        </p>
      </div>
    </div>
    <div class="relative space-y-spacing-sm self-center">
      <div
        v-for="(level, index) in riskLevels"
        :key="index"
        class="flex w-full justify-between gap-spacing-sm bg-secondary-gray-100 px-spacing-md py-spacing-sm2">
        <div class="flex items-center gap-spacing-sm">
          <div :class="`size-4 rounded-md ${level.color}`" />
          <p class="secu-body-bold pb-spacing-xs">
            {{ level.name }}
          </p>
        </div>
        <p class="secu-headline-bold">
          {{ level.totalRisks }}
        </p>
      </div>
    </div>
  </div>
</template>
